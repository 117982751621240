import React, { useEffect, useState, createContext } from "react";
import firebase from "./Firebase";

type ContextProps = {
    currentUser: firebase.User | null;
    authenticated: boolean;
    setUser: any;
    pending: boolean;
};

// Create context for authentication
export const AuthContext = createContext<Partial<ContextProps>>({});

// Provider that stores the authentication status
export const AuthProvider = ({ children }: any) => {
    const [currentUser, setUser] = useState(null);
    const [pending, setPending] = useState(true);
  
    useEffect(() => {
      // Update the current user and pending everytime the authentication state changes in Firebase
      firebase.auth().onAuthStateChanged((currentUser: any) => {
        setUser(currentUser)
        setPending(false)
      });
    }, []);
  
    // If it is pending we return a loding message
    if(pending){
      return <>Loading...</>
    }
  
    return (
      // Pass all props as value to the authcontext provider
      <AuthContext.Provider
        value={{
          currentUser,
          authenticated: currentUser !== null,
          setUser,
          pending
        }}
      >
        {children}
      </AuthContext.Provider>
    );
    
  };