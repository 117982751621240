import React, { useState, useEffect } from 'react';
import './App.css';
import { useHistory } from 'react-router-dom';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import Header from './Header';
import Prismic from '@prismicio/client';
import { Client } from './prismicConfiguration';
import { RichText } from 'prismic-reactjs';

function Contact() {

    const [doc, setDocData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                // Retrieve the contact page from the content repository
                Prismic.Predicates.at('document.type', 'contactpage')
            )
            if (response) {
                // Set doc to the response
                setDocData(response.results[0]);
            }
        }
        fetchData();
    }, [])

    return (
        <div>
            <Header/>
        <div className="background">
            {/** Only render the page if doc and doc.data != null */}
            { doc && doc.data && <div className="informativePage">
                {/** The title for the page */}
                {RichText.render(doc.data.title)}
                {/** The sub heading */}
                {RichText.render(doc.data.subheading)}
                <div className="contactInfo">
                    <div>
                        {/** Icon for email */}
                        <p> <FaEnvelope/> </p>
                        {/** The email */}
                        {RichText.render(doc.data.email)}
                    </div>
                    <div>
                        {/** Icon for phone */}
                        <p> <FaPhone/> </p>
                        {/** The phone number */}
                        {RichText.render(doc.data.phonenumber)}
                    </div>
                    <div>
                        {/** Icon for address */}
                        <p> <FaMapMarkerAlt/> </p>
                        {/** The address */}
                        {RichText.render(doc.data.address)}
                    </div>
                </div>
                        {/* Links to different social media accounts */}
                <div className="socialMedia">
                    <a><FaFacebook/></a>
                    <a><FaInstagram/></a>
                    <a><FaLinkedin/></a>
                </div>
            </div>}
        </div>
        </div>
    );
}

export default Contact;