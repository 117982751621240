import { useState, useEffect } from 'react';
import './signedIn.css';
import Header from '../Header';
import LeftMenu from './LeftMenu';
import Prismic from '@prismicio/client';
import { Client } from '../prismicConfiguration';
import { RichText } from 'prismic-reactjs';

function JointRotations() {

    const [doc, setDocData] = useState(null);

    const [currentVid, setCurrentVid] = useState("video1");

    useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                // Retrieve the joint rotations page from the content repository
                Prismic.Predicates.at('document.tags', ['JointRotations'])
            )
            if (response) {
                // Set doc to the response
                setDocData(response.results[0]);
            }
        }
        fetchData();
    }, [])

    // Creates the list with all the small videos 
    const createScrollList = () =>{
        const videos= [];
        //Loops through all elements in doc.data (minus the components that aren't videos/titles for videos) 
        for (let index = 1; index < Object.keys(doc.data).length-3; index++) {
            // Checks if we have reached the end of the videos
            if(doc.data["title" + index.toString()].length == 0){
                break;
            }
            // Skip the current video
            else if(currentVid == "video" + index){
                //skip
            }
            else{
                videos.push(
                    <div className="videoAndTitle" key={index} onClick={() => changeVid(index)}>
                        <div className="smallVideo">   
                            <iframe src={doc.data["video" + index.toString()].embed_url + "?controls=0"}/>
                        </div>
                        {RichText.render(doc.data["title" + index.toString()])}
                    </div>
                )
            }
        }
        return (<div > {videos} </div>);
    }

    // Changes the current video (i.e. the big video)
    const changeVid = (videoNr: number) => {
        setCurrentVid("video" + videoNr.toString());
        return true;
    }

    return (
        <div>
            {/* The header and leftMenu is temporarily placed here */}
            <Header/>
            <LeftMenu/>
            <div className="signedIn">
                {/** Only render the page if doc and doc.data != null */}
                { doc && doc.data && <div>
                    {/** The title for the page */}
                    {RichText.render(doc.data.title)}
                    <div className="topInformation">
                        <div className="textBox">
                            {/** The title for the box */}
                            {RichText.render(doc.data.title_in_box)}
                            {/** The descriptive text in the box */}
                            {RichText.render(doc.data.descriptive_text_in_box)}
                        </div>
                        {/* Image of e.g. the mascot */}
                        <img src={doc.data.top_image.url} />
                    </div>
                    <div className="videoContainer">
                        <iframe src={doc.data[currentVid].embed_url + "?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} allow="autoplay; fullscreen; picture-in-picture"  className="bigVideo"/>  
                    </div>
                    <div className="scrollList">
                        {createScrollList()}
                    </div> 
                </div> }
            </div>
        </div>
    );
}

export default JointRotations;