import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import firebase from "./Firebase";
import "firebase/auth";
import "firebase/firestore";
import { AuthContext } from "./Auth";

interface UserData {
  email: string;
  password: string;
}

const TemporaryLogin = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const [values, setValues] = useState({
      email: "",
      password: ""
  } as UserData);

  // Triggered when a change is made in the input fields
  const handleChange = (event: any) => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  }
   
  // Triggered when the form is submitted
  const handleSubmit = (event: any) => {
    // Prevent reloading the page when user presses submit button
    event.preventDefault();
    // Validate email and password and pass them to signInWithEmailAndPassword
    firebase.auth().signInWithEmailAndPassword(values.email, values.password)
    // If the user login in was successful
    .then(res => {
      // Set user to the respone
      authContext.setUser(res);
      console.log(res, 'res')
      // Redirect to '/home'
      history.push("/home");
    })
    // Catch any errors and alert the user
    .catch(error => {
      console.log(error.message);
      alert(error.message);
    });
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h2> Logga in </h2>
        <label>
            <h3> Epost: </h3>
            <input name="email" type="email" value={values.email}  onChange={handleChange} placeholder="example@mail.com"></input>
        </label>
        <label>
            <h3> Lösenord: </h3>
            <input name="password" type="password" value={values.password}  onChange={handleChange} placeholder="Ditt lösenord"></input>
        </label>
        <br/>
        <button type="submit">
            Logga in
        </button>
      </form>
    </div>
  );
}
export default TemporaryLogin;
