import React, { useState, useEffect } from 'react';
import './App.css';
import { useHistory } from 'react-router-dom';
import mascot from './images/mascot.png';
import Header from './Header';
import Prismic from '@prismicio/client';
import { Client } from './prismicConfiguration';
import { RichText } from 'prismic-reactjs';

function HomePage() {

    const [doc, setDocData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                // Retrieve the home page from the content repository
                Prismic.Predicates.at('document.type', 'homepage')
            )
            if (response) {
                // Set doc to the response
                setDocData(response.results[0]);
            }
        }
        fetchData();
    }, [])

    return (
        <div>
            {/* Temporarly added header here */}
            <Header/>
        <div className="background">
            {/** Only render the page if doc and doc.data != null */}
            { doc && doc.data && <div>
                <div className="top">
                    {/** Image of e.g. the mascot */}
                    <img className="mascot" src={doc.data.first_image.url}/>
                    <div className="pitch">
                        {/* Title at the top (h1) */}
                        {RichText.render(doc.data.title)}
                        {/** The sub heading */}
                        {RichText.render(doc.data.sub_heading)}
                        {/** An introductory text */}
                        {RichText.render(doc.data.intro_text)}
                        {/** A quote */}
                        {RichText.render(doc.data.quote)}
                    </div>
                </div>
                <div className="middle">
                    {/* Image of evolution*/}
                    <img className="evolutionImg" src={doc.data.evolution_image.url}/>
                        <div className="evolutionInfo">
                            {/** Image of the mascot */}
                            <img src={doc.data.mascot_evolution.url}/>
                            {/** Text describing evolution */}
                            {RichText.render(doc.data.evolution_text)}
                        </div>
                </div>
                <div className="last">
                    <div className="images">
                        {/** Image of the mascot */}
                        <img className="mascot" src={doc.data.mascot_joints.url}/>
                        {/** Image for joints */}
                        <img className="jointImg" src={doc.data.image_joints.url}/>
                    </div>
                    <div className="jointText">
                        {/** Title for joint text */}
                        {RichText.render(doc.data.joints_title)}
                        {/** Text regaring joints */}
                        {RichText.render(doc.data.joints_text)}
                    </div>
                </div>
            </div>}
        </div>
        </div>
    );
}

export default HomePage;