import React, { useContext }  from 'react';
import './header.css';
import { useHistory } from 'react-router-dom';
import logo from './images/logo.png';
import { AuthContext } from "./Auth";
import firebase from './Firebase';

function Header() {
    const history = useHistory();

    const { authenticated } = useContext(AuthContext);

    // Redirect to the page for login
    const loginText = () => {
        if(authenticated){
            return "Logga ut"
        }
        return "Logga in";
    }

    // If the user isn't logged in, they get redirected to login.
    // Otherwise, they are logged out
    const handleClick = (event: any) => {
        if (!authenticated){
            redirectLogin();
        }
        // Prevent reloading the page when user presses the log out button
        event.preventDefault();
        
        // Sign out the user using firebase
        firebase.auth().signOut()
        // If successful, redirect to the login page
        .then(res => {
           history.push("/login");
         })
      }

    // Redirect to the page for login
    const redirectLogin = () => {
        history.push("/login");
    }

    // Redirect to the "normal" home page if not signed in, else the home page for when signed in
    const redirectHome = () => {
        if (!authenticated){
            history.push("/");
        }
        else{
            history.push("/home");
        }
    }

    // Redirect to the page for contact
    const redirectContact = () => {
        history.push("/contact");
    }

    // Redirect to the page for contact
    const redirectAboutUs = () => {
        history.push("/aboutUs");
    }

    const redirectReferences = () => {
        history.push("/videos/references");
    }

    return (
        <div className="wrapper">
            <a onClick={redirectHome}>
                <img src={logo} className="logo"/>
            </a>
            <div className="links">
                {/* If the user is singed in, Källhänvisning is shown */}
                {authenticated && 
                    <a onClick={redirectReferences}>
                        Källhänvisning
                    </a>
                }
                <a onClick={redirectAboutUs}>
                    Om oss
                </a>
                <a onClick={redirectContact}>
                    Kontakt
                </a>
                <a>
                    Bli medlem
                </a>
                <a onClick={handleClick}>
                    {loginText()}
                </a>
            </div>
            
        </div>
    );
}

export default Header;