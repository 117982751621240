import firebase from 'firebase' 
require('firebase/auth');

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyB4SgwQx2yI3TiH0tBTdBcXtF-XlADgHmk",
    authDomain: "kinwod-weknowit.firebaseapp.com",
    projectId: "kinwod-weknowit",
    storageBucket: "kinwod-weknowit.appspot.com",
    messagingSenderId: "621254103553",
    appId: "1:621254103553:web:cf357ed9c7cc9cb601a600",
    measurementId: "G-H3RJBPE2WW"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Get a reference to the storage service, which is used to create references in your storage bucket
var storage = firebase.storage();

// Create a storage reference from our storage service
var storageRef = storage.ref();

// Reference to test video
var testRef = storageRef.child('PlaceholderVideo.mp4');

export default firebase;