import React, { useState, useEffect } from 'react';
import './signedIn.css';
import LeftMenu from './LeftMenu';
import mascot from '../images/mascot.png';
import Header from '../Header';
import Prismic from '@prismicio/client';
import { Client } from '../prismicConfiguration';
import { RichText } from 'prismic-reactjs';

function VideoPage() {

    const [doc, setDocData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                // Retrieve the about us page from the content repository
                Prismic.Predicates.at('document.type', 'signedinhome')
            )
            if (response) {
                // Set doc to the response
                setDocData(response.results[0]);
            }
        }
        fetchData();
    }, [])

    return (
        <div>
            {/* Temporarly added header here */}
            <Header/>
            <LeftMenu/>
            <div className="signedIn">
                {/** Only render the page if doc and doc.data != null */}
                { doc && doc.data && <div>
                    <div className="intro"> 
                        {/** Image of e.g. the mascot */}
                        <img className="mascot" src={doc.data.topimage.url}/>
                        <div className="pitchText">
                            {/** The title for the page */}
                            {RichText.render(doc.data.title)}
                            {/** The sub heading */}
                            {RichText.render(doc.data.subheading)}
                            {/** A descriptive text */}
                            {RichText.render(doc.data.introdescription)}
                            {/** A quote */}
                            {RichText.render(doc.data.quote)}
                        </div>
                    </div>
                    <div className="evolution" >
                        {/* Image of evolution*/}
                        <img className="evolutionImg" src={doc.data.evolution_image.url}/>
                        <div className="evolutionInfo">
                            {/** Image of the mascot */}
                            <img src={doc.data.evolution_mascot.url}/>
                            {/** Text describing evolution */}
                            {RichText.render(doc.data.evolution_text)}
                        </div>
                    </div>
                    <div className="joints">
                        <div className="images">
                            {/** image of the mascot */}
                            <img className="mascot" src={doc.data.joint_mascot.url}/>
                            {/** Image for joints */}
                            <img className="jointImg" src={doc.data.joint_image.url}/>
                        </div>
                        <div className="jointText">
                            {/** Title for joint text */}
                            {RichText.render(doc.data.joint_title)}
                            {/** Text regaring joints */}
                            {RichText.render(doc.data.joint_text)}
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default VideoPage;