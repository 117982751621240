import React, { useState, useEffect } from 'react';
import './App.css';
import { useHistory } from 'react-router-dom';
import exampleImg from './images/mascot.png';
import Header from './Header';
import Prismic from '@prismicio/client';
import { Client } from './prismicConfiguration';
import { RichText } from 'prismic-reactjs';

function AboutUs() {

    const [doc, setDocData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                // Retrieve the about us page from the content repository
                Prismic.Predicates.at('document.type', 'aboutuspage')
            )
            if (response) {
                // Set doc to the response
                setDocData(response.results[0]);
            }
        }
        fetchData();
    }, [])

    return (
        <div>
            <Header/>
        <div className="background">
            {/** Only render the page if doc and doc.data != null */}
            { doc && doc.data && <div className="informativePage">
                {/** The title for the page */}
                {RichText.render(doc.data.title)}
                {/** The sub heading */}
                {RichText.render(doc.data.subheading)}
                <div className="aboutUs">
                    {/** Image of e.g. the owners */}
                    <img src={doc.data.image.url}/>
                    {/** Informative text */}
                    {RichText.render(doc.data.descriptivetext)}
                </div>
            </div> }
        </div>
        </div>
    );
}

export default AboutUs;