import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom"
import Login from './Login';
import VideoPage from './signedInPages/VideoPage';
import HomePage from './HomePage';
import { AuthProvider } from './Auth';
import PrivateRoute from './PrivateRoute';
import Header from './Header';
import Contact from './Contact';
import AboutUs from './AboutUs';
import Introduction from './signedInPages/Introduction';
import JointRotations from './signedInPages/JointRotations';
import UpperBody from './signedInPages/UpperBody';
import Back from './signedInPages/Back';
import LowerBody from './signedInPages/LowerBody';
import TemporaryLogin from './TemporaryLogin';
import References from './signedInPages/References';

function App() {
  return (
    <AuthProvider>
      <Router>
        {/* The header is always viewed at the top */}
        {/* Temporarly moved to not be visible from the first page
        <Header/>
        */}
        <Switch>
          {/* '/' Temporarly renders a page for login */}
          <Route exact path="/" component={TemporaryLogin}/>
          {/* '/' renders the homepage */}
           {/* Temporarly removed and made private instead with a new path
          <Route exact path="/" component={HomePage}/>
           */}
          <PrivateRoute exact path="/kinwod" component={HomePage}/>
          {/* '/aboutUs' renders the page for information about us */}
          {/* Temporarly removed and made private instead
          <Route exact path="/aboutUs" component={AboutUs}/>
          */}
          <PrivateRoute exact path="/aboutUs" component={AboutUs}/>
          {/* '/contact' renders the page for contact */}
          {/* Temporarly changed it to rivate route
          <Route exact path="/contact" component={Contact}/>
          */}
          <PrivateRoute exact path="/contact" component={Contact}/>
          {/* '/login' renders the page for login */}
          {/* Temporarily changed it to private route
          <Route exact path="/login" component={Login}/>
          */}
          <PrivateRoute exact path="/login" component={Login}/>
          {/* '/videos' is limited to users that are signed in and shows the videos */}
          <PrivateRoute exact path="/home" component={VideoPage}/>
          {/* '/videos/introduction' is limited to users that are signed in and shows the introduction */}
          <PrivateRoute exact path="/videos/introduction" component={Introduction}/>
          {/* '/videos/jointrotations' shows the page for joint rotations and is only accessible if the user has signed in */}
          <PrivateRoute exact path="/videos/jointrotations" component={JointRotations}/>
          {/* Page for upper boody is limited to signed in users*/}
          <PrivateRoute exact path="/videos/upperBody" component={UpperBody}/>
          {/* Page for back/neck is limited to signed in users*/}
          <PrivateRoute exact path="/videos/back" component={Back}/>
          {/* Page for lower body is limited to signed in users*/}
          <PrivateRoute exact path="/videos/lowerbody" component={LowerBody}/>
          {/* Page showing references is only accessible if the user has signed in */}
          <PrivateRoute exact path="/videos/references" component={References}/>
        </Switch>
      </Router>
    </AuthProvider>
      
  );
}

export default App;
