import React, { useState, useEffect } from 'react';
import './signedIn.css';
import Prismic from '@prismicio/client';
import { Client } from '../prismicConfiguration';
import { RichText } from 'prismic-reactjs';
import LeftMenu from './LeftMenu';
import introduction1 from '../images/introduction1.png';
import Header from '../Header';

function Introduction() {

    const [doc, setDocData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await Client.query(
                // Retrieve the about us page from the content repository
                Prismic.Predicates.at('document.type', 'introductionpage')
            )
            if (response) {
                // Set doc to the response
                setDocData(response.results[0]);
            }
        }
        fetchData();
    }, [])

    return (
        <div>
            {/** Temporarily added header here */}
            <Header/>
            <LeftMenu/>
            <div className="signedIn">
                {/** Only render the page if doc and doc.data != null */}
                { doc && doc.data && <div>
                    {/** The title for the page */}
                    {RichText.render(doc.data.title)}
                    <div className="block">
                        {/** Introductory image */}
                        <img className="leftImg" src={doc.data.introduction_image.url}/>
                        {/** Introductory text */}
                        <div className="introText">
                            {RichText.render(doc.data.introduction_text)}
                        </div>
                    </div>
                    <div className="block">
                        {/** Text regarding pain */}
                        <div className="introText">
                            {RichText.render(doc.data.pain_title)}
                            {RichText.render(doc.data.pain_text)}
                        </div>
                        {/** Image for pain */}
                        <img className="rightImg" src={doc.data.pain_image.url}/>
                    </div>
                    <div className="block">
                        {/** Image for ROM */}
                        <img className="leftImg" src={doc.data.rom_image.url}/>
                        {/** Text regarding ROM */}
                        <div className="introText">
                            {RichText.render(doc.data.rom_title)}
                            {RichText.render(doc.data.rom_sub_heading)}
                        </div>
                    </div>
                    <div className="block">
                        {/** Text regarding power */}
                        <div className="introText">
                            {RichText.render(doc.data.power_text)}
                        </div>
                        {/** Image for power */}
                        <img className="rightImg" src={doc.data.power_image.url}/>
                    </div>
                    <div className="block">
                        {/** Image for CAR */}
                        <img className="leftImg" src={doc.data.car_image.url}/>
                        {/** Text regarding CAR */}
                        <div className="introText">
                            {RichText.render(doc.data.car_text)}
                        </div>
                    </div>
                    <div className="block">
                        {/** Text regarding rotation */}
                        <div className="introText">
                            {RichText.render(doc.data.rotation_text)}
                        </div>
                        {/** Image for rotation */}
                        <img className="rightImg" src={doc.data.rotation_image.url}/>
                    </div>
                    <div className="block">
                        {/** Image for irradiation */}
                        <img className="leftImg" src={doc.data.irradiation_image.url}/>
                        {/** Text regarding irradiation */}
                        <div className="introText">
                            {RichText.render(doc.data.irradiation_title)}
                            {RichText.render(doc.data.irradiation_text)}
                        </div>
                    </div>
                    <div className="footer"/>
                </div>}
            </div>
        </div>
    );
}

export default Introduction;