import React from 'react';
import './signedIn.css';
import { useHistory } from 'react-router-dom';
import Header from '../Header';
import LeftMenu from './LeftMenu';

function References() {

    return (
        <div>
            {/** Temporarly added header and left menu here */}
            <Header/>
            <LeftMenu/>
            <div className="signedIn">
                <h1> Källhänvisning </h1>
            </div>
        </div>
    );
}

export default References;