import React from 'react';
import './leftMenu.css';
import { NavLink } from 'react-router-dom';

function LeftMenu() {

    return (
        <div className="leftMenu">
            <NavLink to='/home'> Startsida </NavLink>
            <NavLink to='/videos/introduction'> Introduktion </NavLink>
            <NavLink to='/videos/jointrotations'> Ledrotationer </NavLink>
            <NavLink to='/videos/upperbody'> Överkropp </NavLink>
            <NavLink to='/videos/back'> Rygg/Nacke </NavLink>
            <NavLink to='/videos/lowerbody'> Underkropp </NavLink>
        </div>
    );
}

export default LeftMenu;