import Prismic from '@prismicio/client';

// Endpoint address of the repository
const apiEndpoint = 'https://kinwod.cdn.prismic.io/api/v2';
const accessToken = 'MC5ZRVNsY3hJQUFDWUFmU0dn.NCvvv73vv73vv73vv700GjJe77-977-977-9bO-_ve-_ve-_ve-_ve-_vSw_77-9Uinvv70CHWfvv70yJ2Q' // This is where you would add your access token for a Private repository

// Initialize the Client interface
export const Client = Prismic.client(apiEndpoint, {accessToken} );


