import React, { useContext } from 'react';
import {
    Route,
    Redirect,
    RouteProps,
} from 'react-router-dom';
import { AuthContext } from "./Auth";
import LeftMenu from './signedInPages/LeftMenu';

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  // RouteComponent should be rendered if the user is authenticated
  const { component: RouteComponent, ...rest } = props;
  // Value authenticated from authentication provider is true if there is a user
  const { authenticated } = useContext(AuthContext);
  
  return(
    <Route 
      {...rest} 
      render={props => 
        // If authenticated is true, render the left menu and the route component
        authenticated ? (
          /* Temporarily removed because of using password for the website
          <div>
            <LeftMenu/>
            <RouteComponent {...props}/>
          </div> */
          <RouteComponent {...props}/>
        ) : (
          // Otherwise, redirect to '/login'
          <Redirect to={{
            /* Temporarily changed it to the temporarily login page 
            pathname: '/login'
            */
           pathname: './'
          }}/>
        ) 
      }/>
  )
}

export default PrivateRoute;